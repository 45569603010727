<template>
	<div class="page">
		<!-- 搜索栏 -->
		<div class="tabView">
			<div class="tabViewT">
				<div class="tabItem" v-if="$buttonAuthority('dianpu-dianyuanList-staff')" :class="tab == 0?'active':''" @click="handelTabClick(0)">店员管理</div>
				<div class="tabItem" v-if="('dianpu-dianyuanList-Position')" :class="tab == 1?'active':''" @click="handelTabClick(1)">职位管理</div>
			</div>
			<div class="tabViewB">
				<el-input v-model="keyword" placeholder="请输入姓名/账号" prefix-icon="iconfont el-icon-search"
					style="width: 200px;margin-right: 8px;" v-if="tab == 0" @keyup.enter.native="handleSearchClick"></el-input>
				<el-button type="primary" style="margin-right: 32px;" @click="handleSearchClick" v-if="tab == 0">查询</el-button>
				<div class="search" v-if="tab == 0">
					<div class="searchTitle">职位:</div>
					<el-select v-model="rouleId" placeholder="请选择" style="width: 143px;" @change="handleSearchClick">
						<el-option v-for="item in rouleList" :key="item.roule.id" :label="item.roule.name"
							:value="item.roule.id">
						</el-option>
					</el-select>
				</div>
				<div class="search" v-if="tab == 0">
					<div class="searchTitle">预约:</div>
					<el-select v-model="isMake" placeholder="请选择" style="width: 143px;" @change="handleSearchClick">
						<el-option v-for="item in isMakeOption" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="search" v-if="tab == 0">
					<div class="searchTitle">状态:</div>
					<el-select v-model="isLeave" placeholder="请选择" style="width: 143px;" @change="handleSearchClick">
						<el-option v-for="item in isLeaveOption" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="tabViewBR">
					<el-button @click="handleResetClick" v-if="tab == 0">重置</el-button>
					<el-button type="primary"  @click="handleAddClick" v-if="$buttonAuthority('dianpu-dianyuanList-staff-add')&&tab == 0" >新建店员</el-button>
					<el-button type="primary" v-if="tab == 1&&$buttonAuthority('dianpu-dianyuanList-Position-add')"   @click="dialogTypeSetting_state = true;nodeData = positionData;addType = 7">新建职位</el-button>
				</div>
			</div>
		</div>

		<!-- 表格 -->
		<div class="tableView">
			<!-- 店员管理 -->
			<el-table :data="clerkData" style="width: 100%" v-if="tab == 0" key="1">
				<el-table-column prop="roleInfo.projectName" label="店员职位">
				</el-table-column>
				<el-table-column prop="basicInfo.userMobile" label="店员账号">
				</el-table-column>
				<el-table-column prop="basicInfo.realName" label="店员姓名">
				</el-table-column>
				<el-table-column prop="operationInfo.create.handleTime" label="创建时间">
				</el-table-column>
				<el-table-column prop="lastLoginTime" label="最后登录时间">
					<template slot-scope="scope">
						{{scope.row.lastLoginTime == null?'--':$public.FTime(scope.row.lastLoginTime,'YYYY-MM-DD HH:mm:ss')}}
					</template>
				</el-table-column>
				<el-table-column prop="basicInfo.isMake" label="是否可预约服务">
					<template slot-scope="scope">
						{{scope.row.basicInfo.isMake == true?'可预约':'不可预约'}}
					</template>
				</el-table-column>
				<el-table-column prop="isLeave" label="状态" width="100">
					<template slot-scope="scope">
						{{scope.row.basicInfo.isLeave == true?'离职':'正常'}}
					</template>
				</el-table-column>
				<el-table-column label="操作" width="100">
					<template slot-scope="scope">
						<el-button v-if="$buttonAuthority('dianpu-dianyuanList-staff-edit')" @click="handleEditRouleClick(scope.row)" type="text" size="small">编辑</el-button>
						<el-button v-if="$buttonAuthority('dianpu-dianyuanList-staff-del')" type="text" size="small" @click="handelDeleteClick(0,scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			
			<!-- 职位管理 -->
			<el-table :data="positionTableData" style="width: 100%" v-if="tab == 1" key="2">
				<el-table-column prop="roule.name" label="职位">
				</el-table-column>
				<el-table-column prop="staffCount" label="职位人数">
				</el-table-column>
				<el-table-column label="提成设置">
					<template slot-scope="scope">
						<span style="color: #F77E04;" v-if="$buttonAuthority('dianpu-dianyuanList-Position-royalty')" @click="handleSetClick(scope.row)">设置</span>
					</template>
				</el-table-column>
				<el-table-column label="权限设置">
					<template slot-scope="scope">
						<span style="color: #F77E04;" v-if="$buttonAuthority('dianpu-dianyuanList-Position-role')" @click="handleAuthorityClick(scope.row)">设置</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="100">
					<template slot-scope="scope">
						<el-button v-if="$buttonAuthority('dianpu-dianyuanList-Position-edit')" @click="handleEditClick(scope.row)" type="text" size="small">编辑</el-button>
						<el-button type="text" v-if="$buttonAuthority('dianpu-dianyuanList-Position-del')" size="small" @click="handelDeleteClick(1,scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
		</div>
		<!-- 职位弹框 -->
		<div v-if="dialogTypeSetting_state">
			<dialogTypeSetting @submit="submit_dialogTypeSetting" :addType="addType" :nodeData="nodeData">
			</dialogTypeSetting>
		</div>
		<!-- 提成弹框 -->
		<div v-if="dialogCommission_state">
			<dialogCommission @submit="submit_dialogCommission_state" :commissionData="commissionData">
			</dialogCommission>
		</div>
		<!-- 提成设置弹框 -->
		<div v-if="dialogSetRoyalty_state">
			<dialogSetRoyalty @submit="submit_dialogSetRoyalty_state" :routerType="routerType" :roule="roule">
			</dialogSetRoyalty>
		</div>
		<!-- 权限设置弹框 -->
		<div v-if="dialogAuthority_state">
			<dialogAuthority @submit="submit_dialogAuthority_state" :routerMsg="routerMsg">
			</dialogAuthority>
		</div>
	</div>
</template>

<script>
	import dialogTypeSetting from "../../../components/dialogTypeSetting.vue"
	import dialogCommission from "../../../components/dialogCommission.vue"
	import dialogSetRoyalty from "../../../components/dialogSetRoyalty.vue"
	import dialogAuthority from "../../../components/dialogAuthority.vue"
	import page from "../../../components/page.vue"
	export default {
		components:{
			page,
			dialogTypeSetting,
			dialogCommission,
			dialogSetRoyalty,
			dialogAuthority
		},
		data() {
			return {
				page:{   //分页
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				pageSizes: [10, 15,30,60],
				tab:0,
				clerkData:[],  //店员管理
				dialogTypeSetting_state:false,  //职位弹框
				positionData:null,  //职位数据
				positionTableData:[],  //职位列表
				addType:4,  //4为新建
				nodeData:null,  //提交数据
				dialogCommission_state:false,  //提成
				commissionData:{},  //跳转提成数据
				dialogSetRoyalty_state:false,  //单独设置弹框
				routerType:'',  //跳转状态
				roule:'',  //角色id
				rouleList:[],  //店员管理-职位列表
				rouleId:'',  //店员管理-职位筛选id
				keyword:'',
				isMake:-1,
				isMakeOption:[{label:'全部',value:-1},{label:'可预约',value:1},{label:'不可预约',value:0}],
				isLeave:-1,
				isLeaveOption:[{label:'全部',value:-1},{label:'离职',value:1},{label:'正常',value:0}],
				dialogAuthority_state:false,  //权限弹框
				routerMsg:{},  //跳转权限
			}
		},
		mounted() {
			if(this.tab == 0){
				this.getClerkData()
				this.getRouleData()
			}else{
				this.getPositionData()
				this.getRouleList()
			}
			
		},
		methods: {
			//关闭权限弹框
			submit_dialogAuthority_state(){
				this.dialogAuthority_state = false;
			},
			//权限设置
			handleAuthorityClick(row){
				this.routerMsg = row;
				this.dialogAuthority_state = true;
			},
			//编辑店员
			handleEditRouleClick(row){
				let query = {
					routeData:JSON.stringify(row)
				}
				this.$router.push({path: 'dianyuanAdd',query})
			},
			//新建店员
			handleAddClick(){
				this.$router.push({path: 'dianyuanAdd',query:{}})
			},
			//重置
			handleResetClick(){
				this.keyword = '';
				this.page.pageIndex = 1;
				this.rouleId = '';
				this.isLeave = -1;
				this.isMake = -1;
				this.getClerkData()
			},
			//筛选
			handleSearchClick(){
				this.page.pageIndex = 1;
				this.getClerkData();
			},
			//获取店员列表
			getClerkData(){
				let params = {
					pageIndex:this.page.pageIndex,
					pageSize:this.page.pageSize,
					keyWord: this.keyword,
					roleId: this.rouleId,
					isLeave:this.isLeave,
					isMake:this.isMake
				}
				this.$http.post("/cms/StaffInfo/GetStaffInfoList", params).then(res => {
					if (res.code === 0) {
						this.clerkData = res.data.list;
						this.total = res.data.count;
					}
				})
			},
			//获取职位列表
			getRouleData(){
				let params = {
					pageIndex:0,
					pageSize:0,
				}
				this.$http.post("/cms/System/GetRouleList", params).then(res => {
					if (res.code === 0) {
						this.rouleList = res.data.list;
					}
				})
			},
			//关闭弹框
			submit_dialogSetRoyalty_state(){
				this.dialogSetRoyalty_state = false;
				this.dialogCommission_state = true;
			},
			//删除职位
			handelDeleteClick(type,row){
				if(type == 0){
					this.$confirm('是否删除该店员？', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						let params = {
							staffId:row.id,
						}
						this.$http.get("/cms/StaffInfo/DeleteStaffInfo", params).then(res => {
							if (res.code === 0) {
								this.$message({
									type: 'info',
									message: '已删除'
								})
								this.getClerkData()
							}
						})
					})
				}else{
					this.$confirm('是否删除该职位？', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						let params = {
							id:row.roule.id,
						}
						this.$http.get("/cms/System/DeleteSystemDiction", params).then(res => {
							if (res.code === 0) {
								this.$message({
									type: 'info',
									message: '已删除'
								})
								this.getRouleList()
							}
						})
					})
				}
				
			},
			//编辑
			handleEditClick(row){
				this.nodeData = row.roule;
				this.addType = 8;
				this.dialogTypeSetting_state = true;
			},
			//关闭提成
			submit_dialogCommission_state(obj){
				if(obj.data.routerType == 0){
					this.dialogCommission_state = false;
				}else{
					this.routerType = obj.data.routerType;
					this.roule = obj.data.roule;
					this.dialogCommission_state = false;
					this.dialogSetRoyalty_state = true;
				}
				
			},
			//提成设置
			handleSetClick(row){
				this.commissionData = row;
				this.dialogCommission_state = true;
			},
			//获取职位列表
			getRouleList(){
				let params = {
					pageIndex:this.page.pageIndex,
					pageSize:this.page.pageSize,
				}
				this.$http.post("/cms/System/GetRouleList", params).then(res => {
					if (res.code === 0) {
						this.positionTableData = res.data.list;
						this.page.total = res.data.count;
					}
				})
			},
			//关闭弹框
			submit_dialogTypeSetting(){
				this.getRouleList();
				this.dialogTypeSetting_state = false;
			},
			//获取职位信息
			getPositionData(){
				let params = {
					code:'roulecode'
				}
				this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
					if (res.code === 0) {
						this.positionData = res.data;
					}
				})
			},
			//切换分页
			changePage(num){
				this.page.pageIndex = num
				if(this.tab == 0){
					this.getClerkData()
				}else{
					this.getPositionData()
				}
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			if(this.tab == 0){
					this.getClerkData()
				}else{
					this.getPositionData()
				}
		},
			//切换
			handelTabClick(type){
				this.tab = type;
				this.page.page = 1;
				if(this.tab == 0){
					this.getClerkData()
					this.getRouleData()
				}else{
					this.getPositionData()
					this.getRouleList()
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		width: 100%;
		height: 100%;
		color: $fontColor;
		display: flex;
		flex-direction: column;

		// 搜索信息
		.tabView {
			height: 161px;
			border-radius: 20px;
			background: #fff;
			display: flex;
			flex-direction: column;
			margin-bottom: 16px;

			.tabViewT {
				height: 80px;
				border-bottom: solid 1px #F2F2F2;
				display: flex;
				align-items: center;

				.tabItem {
					width: 116px;
					line-height: 40px;
					text-align: center;
					font-size: 16px;
					border-radius: 40px;
					margin-left: 20px;
					cursor: pointer;
					background: #FFF0E0;
				}

				.active {
					background: #F77E04;
					color: #fff;
				}
			}

			.tabViewB {
				flex: 1;
				display: flex;
				align-items: center;
				padding: 0 20px;

				.search {
					display: flex;
					align-items: center;
					margin-right: 32px;

					.searchTitle {
						font-size: 14px;
						color: $fontColor;
						margin-right: 4px;
					}
				}

				.tabViewBR {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: flex-end;
				}
			}
		}

		// 表格
		.tableView {
			flex: 1;
			overflow-y: scroll;
			background: #fff;
			border-radius: 20px;
			display: flex;
			flex-direction: column;
			
		}

		.tableView::-webkit-scrollbar {
			display: none;
		}
	}
</style>
