<template>
	<div class="HSMask">
		<div class="HSDialog" >
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">权限设置</div>
				<img class="HSDialogCloseImg" src="../assets/img/HS/close.png" @click="close" />
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<!-- 自定义部分 -->
			<div class="HSDialogContent">
				<div class="NameList">
					<div v-for="(item, index) in treeData" :key="index" :class="tab == index ? 'NameActive' : ''"
						@click="changeTab(item, index)">{{ item.name }}</div>
					<!-- <el-button type="primary" @click="getCheckedCodes">提交</el-button> -->
				</div>
				<el-tree class="tree" :data="tableData" :props="defaultProps" show-checkbox node-key="id" ref="rightsTree"
					:current-node-key="roleSelected" default-expand-all :default-checked-keys="roleArray" icon-class=" " style="padding:20px 30px;box-sizing: border-box;width: 1000px;height: 600px;overflow: auto;">
					<span v-if="data.menuType === 3 && data.itemList.length === 0" class="btn-node" slot-scope="{ data }">
						<span> <i class="el-icon-mouse" size="60"></i>{{ data.name }}</span>
					</span>
					<span v-else-if="data.menuType === 2" class="custom-tree-node" slot-scope="{ data }">
						<span><i class="el-icon-s-order" size="max"></i>{{ data.name }}</span>
					</span>
					<span v-else-if="data.menuType === 1" class="custom-tree-node" slot-scope="{ data }">
						<span> <i class="el-icon-s-tools"></i>{{ data.name }}</span>
					</span>
				</el-tree>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">关闭</div>
				<div class="HSDialogButtonConfirm" @click="submit">保存</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		routerMsg: {   //跳转信息
			default() {
				return {}
			}
		}
	},
	data() {
		return {
			tab: 0,
			defaultProps: {
				children: "itemList",
				label: "name",
				// disabled(data) { // 角色应有的权限 不可改
				//   return data.isChecked == true
				// }
			},
			treeData: [],
			roleArray: [],
			tableData: [],
			roleSelected: "",
			systemCode: '',
			companyId: this.$store.state.userInfo.companyInfo.id,
			companyName: this.$store.state.userInfo.companyInfo.name,
		}
	},
	mounted() {
		console.log(this.routerMsg, '权限设置');
		this.getTreeData()
		this.$nextTick(() => {
			var threeFuse = document.getElementsByClassName('btn-node');
			for (var i = 0; i < threeFuse.length; i++) {
				threeFuse[i].parentNode.style.cssFloat = 'left';
				threeFuse[i].parentNode.style.styleFloat = 'left';
			}
		})
	},
	methods: {
		// 修改员工权限
		submit() {
			this.roleArray = this.$refs.rightsTree.getCheckedNodes();
			const checkedCodes = this.roleArray.map(node => node.id);
			let systemInfo = {
				projectId: '',
				projectName: '',
				projectCode: this.systemCode
			}
			let roleInfo = {
				projectId: this.routerMsg.roule.id,
				projectName: this.routerMsg.roule.name,
				projectCode: this.routerMsg.roule.code
			}
			let companyInfo = {
				id: this.companyId,
				name: this.companyName
			}
			let params = {
				controlType: 2,
				roleInfo: roleInfo,
				systemInfo: systemInfo,
				companyInfo: companyInfo,
				controlList: checkedCodes,
			};
			this.$http
				.post("/cms/RightControl/AddRightControl", params)
				.then((res) => {
					if (res.code == 0) {
						this.$message({
							type: "success",
							message: "成功",
						});
						this.$emit("submit", { type: "success", data: "自定义数据" })
					}
				})
		},
		getRoleTree() {
			let params = {
				CompanyId: this.companyId,
				RouleCode: this.routerMsg.roule.code,
				systemCode: this.systemCode,
			}
			this.$http.get("/cms/RightControl/GeRouleTreeList", params).then(res => {
				if (res.code === 0) {

					this.tableData = res.data ? res.data.list : [];
					this.roleArray = res.data ? res.data.ids : []
					setTimeout(() => {
						this.changeTreeClass()
					}, 0)
				}
			})
		},
		changeTab(item, index) {
			this.tab = index
			this.systemCode = item.code
			this.getRoleTree(this.systemCode)
		},
		//获取树
		getTreeData() {
			let params = {
				code: 'systemlist'
			}
			this.$http.get("cms/System/GetSystemDiction", params).then(res => {
				if (res.code === 0) {
					console.log(res,'权限树');
					this.treeData = res.data.itemList;
					this.systemCode = this.treeData[0].code
					this.getRoleTree(this.systemCode)
				}
			})
		},
		// 设置按钮节点水平排列
		changeTreeClass() {
			var classDomList = document.getElementsByClassName('btn-node')
			for (let i = 0; i < classDomList.length; i++) {
				console.log(classDomList[i]);
				// 给自定义class节点的父节点设置样式
				classDomList[i].parentNode.style.float = 'left'
				classDomList[i].parentNode.style.paddingLeft = 0
				classDomList[i].parentNode.parentNode.style.paddingLeft = '36px'
			}
		},
		// 关闭
		close() {
			this.$emit("submit", { type: "close", data: "" })
		},
		// 提交
		// submit() {
		// 	this.$emit("submit", { type: "success", data: "自定义数据" })
		// }

	}
};
</script>
<style lang="scss" scoped>
.HSDialog {
	display: flex;
	flex-direction: column;
}

.HSDialogButtonLine {
	position: static;
}

.HSDialogContent {
	flex: 1;
	display: flex;
	flex-direction: column;

	.NameList {
		display: flex;

		div {
			background-color: rgb(208, 204, 197);
			// border: 1px solid gray;
			margin: 10px 10px;
			color: #fff;
			border-radius: 30px;
			padding: 10px 20px;
		}

		.NameActive {
			background-color: rgb(225, 161, 32);
			// border: 1px solid gray;
			margin: 10px 10px;
			color: #fff;
			border-radius: 30px;
			padding: 10px 20px;
		}
	}
}

.pageRView {
	flex: 1;
	padding-top: 10px;
	display: flex;

	.pageRViewL {
		width: 236px;
		border-right: solid 1px #cecece;
		overflow-y: scroll;
	}

	.pageRViewR {
		flex: 1;
		overflow-y: scroll;
	}

	.pageRViewL::-webkit-scrollbar {
		display: none;
	}

	.pageRViewR::-webkit-scrollbar {
		display: none;
	}
}

// tab切换
.tabView {
	display: flex;
	align-items: center;
	box-sizing: border-box;
	border-bottom: solid 1px #cecece;
	height: 60px;
	font-size: 14px;
	cursor: default;

	.tabItem {
		width: 80px;
		line-height: 30px;
		text-align: center;
		font-size: 14px;
		border-radius: 30px;
		margin-left: 20px;
		cursor: pointer;
		background: #FFF0E0;
	}

	.active {
		background: #F77E04;
		color: #fff;
	}
}
</style>
