<template>
	<div class="HSMask">
		<div class="HSDialog" style="width:1000px;height: 594px;">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">{{dialogName}}</div>
				<img class="HSDialogCloseImg" src="../assets/img/HS/close.png" @click="close" />
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div class="HSDialogContent">
				<!-- 搜索 -->
				<div class="searchView">
					<el-input v-model="keyword" placeholder="请输入查询信息" prefix-icon="iconfont el-icon-search"
						style="width: 200px;margin-right: 8px;margin-left: 16px;"></el-input>
					<el-button type="primary" style="margin-right: 32px;" @click="handleSearchClick">查询</el-button>
					<el-select v-model="isSpecial" placeholder="请选择" style="width: 110px;margin-right: 32px;" @change="handleSearchClick">
						<el-option v-for="item in isSpecialOption" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
					<el-button style="margin-right: 12px;" @click="handleResetClick">重置</el-button>
					<el-button style="margin-right: 12px;" @click="handleSaveClick">保存本页提成配置</el-button>
					<div class="searchTxt">(提示:请先保存当页配置再进行翻页)</div>
				</div>
				<!-- 列表 -->
				<div class="tableView">
					<div class="tableViewL" v-if="routerType == 1">
						<el-tree :data="treeData.itemList" :props="treeProps" @node-click="handleNodeClick" :highlight-current='isHighlight' node-key="id"
							default-expand-all :expand-on-click-node="false"></el-tree>
					</div>
					<div class="tableViewR">
						<div class="tableWarp">
							<el-table :data="tableData" style="width: 100%">
								<el-table-column prop="barCode" label="条形码" key="1" v-if="routerType == 1">
								</el-table-column>
								<el-table-column prop="title" label="名称" key="2">
								</el-table-column>
								<el-table-column prop="model" label="规格" key="3" v-if="routerType == 1">
								</el-table-column>
								<el-table-column prop="currentCostPrice" label="进货价" key="4" v-if="routerType == 1">
								</el-table-column>
								<el-table-column prop="salePrice" label="零售价" key="5">
								</el-table-column>
								<el-table-column label="提成百分比" key="6" width="140">
									<template slot-scope="scope">
										<el-input placeholder="请输入提成" v-model="scope.row.productRoyaltyRatio" size="small" style="width: 130px;">
											<template slot="append">%</template>
										</el-input>
									</template>
								</el-table-column>
								<el-table-column width="100">
									<template slot-scope="scope">
										<el-button type="text" size="small" @click="handleApplyClick(scope.row)">应用到本页</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<!-- 分页 -->
						<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total"
							@change="changePage"></page>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import page from "./page.vue"
	export default {
		components: {
			page
		},
		props: {
			routerType: {
				default: ''
			},
			roule:{
				default(){
					return {}
				}
			}
		},
		data() {
			return {
				page: { //分页
					pageIndex: 1,
					pageSize: 10,
					total: 0,
				},
				pageSizes: [10, 15,30,60],
				dialogName: '', //弹框名称
				treeData: {}, //树形列表
				treeProps: {
					children: 'itemList',
					label: 'name'
				},
				isSpecial:-1,  //是否特殊提成
				isSpecialOption:[{label:'全部',value:-1},{label:'未设置',value:0},{label:'已设置',value:1}],
				keyword:'',
				isHighlight: true,
				tableData:[],  //表格
				categoryId:'',  //分类id
			}
		},
		mounted() {
			if (this.routerType == 1) {
				this.getTreeData()
				this.dialogName = '商品提成设置'
			} else if (this.routerType == 4) {
				this.dialogName = '套餐提成设置'
			} else if (this.routerType == 2) {
				this.dialogName = '服务提成设置'
			}
			this.getTableData()
		},
		methods: {
			//筛选
			handleSearchClick(){
				this.page.pageIndex = 1;
				this.getTableData()
			},
			//保存
			handleSaveClick(){
				let params = {
					roleInfo:{
						projectId:this.roule.id,
						projectName:this.roule.name,
						projectCode:this.roule.code
					},
					productType:this.routerType,
				}
				//商品
				let arr = [];
				this.tableData.map((item)=>{
					arr.push({
						productId:item.id,
						productRoyaltyRatio:Number(item.productRoyaltyRatio)
					})
				})
				params.productRoyaltyList = arr;
				this.$http.post("/cms/RoyaltyConfig/SetRoyaltyConfigDetail", params).then(res => {
					if (res.code === 0) {
						this.$message({
							type: 'success',
							message: '已保存'
						})
					}
				})
			},
			//应用到本页
			handleApplyClick(row){
				this.tableData.map((item)=>{
					item.productRoyaltyRatio = row.productRoyaltyRatio
				})
			},
			//重置
			handleResetClick(){
				this.page.pageIndex = 1;
				this.keyword = '';
				this.categoryId = '';
				this.isSpecial = -1;
				this.getTableData()
			},
			//获取表格
			getTableData(){
				let params = {
					pageIndex:this.page.pageIndex,
					pageSize:this.page.pageSize,
					keyWord:this.keyword,
					rouleId:this.roule.id,
					categoryId:this.categoryId,
					productType:this.routerType,
					isSpecial:this.isSpecial
				}
				this.$http.post("/cms/RoyaltyConfig/GetProducntRoyaltyList", params).then(res => {
					if (res.code === 0) {
						this.tableData = res.data.list;
						this.page.total = res.data.count;
					}
				})
			},
			//切换分页
			changePage(num) {
				this.page.pageIndex = num
				this.getTableData();
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
			//点击树节点
			handleNodeClick(data) {
				this.categoryId = data.id;
				this.page.pageIndex = 1;
				this.getTableData()
			},
			//获取树
			getTreeData() {
				let params = {
					code: 'goodstype'
				}
				this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
					if (res.code === 0) {
						this.treeData = res.data;
					}
				})
			},
			// 关闭
			close() {
				this.$emit("submit", {
					type: "close",
					data: ""
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
	.HSDialogContent {
		height: calc(100% - 60px);
	}

	.searchView {
		display: flex;
		align-items: center;
		height: 73px;
		border-bottom: solid 1px #D8D8D8;
		box-sizing: border-box;

		.searchTxt {
			color: #FF3939;
			font-size: 14px;
			font-weight: bold;
		}
	}

	.tableView {
		height: calc(100% - 73px);
		// background: red;
		display: flex;

		.tableViewL {
			width: 140px;
			overflow-y: scroll;
			box-sizing: border-box;
			padding: 10px;
			border-right: solid 1px #D8D8D8;
		}

		.tableViewR {
			flex: 1;

			display: flex;
			flex-direction: column;

			.tableWarp {
				flex: 1;
				overflow-y: scroll;
			}
		}

		.tableViewL::-webkit-scrollbar {
			display: none;
		}

		.tableWarp::-webkit-scrollbar {
			display: none;
		}
	}
</style>
