<template>
	<div class="HSMask">
		<div class="HSDialog" style="height: 470px;width: 443px;">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">提成设置</div>
				<img class="HSDialogCloseImg" src="../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div class="HSDialogContent">
				<div class="item">
					<div class="itemName">商品提成:</div>
					<el-input placeholder="请输入商品提成" v-model="goodsRoyalty" style="width: 200px;margin-right: 12px;">
						<template slot="append">%</template>
					</el-input>
					<el-button @click="submit(1)">商品详情</el-button>
				</div>
				<div class="item">
					<div class="itemName">套餐提成:</div>
					<el-input placeholder="请输入套餐提成" v-model="serviceRoyalty" style="width: 200px;margin-right: 12px;">
						<template slot="append">%</template>
					</el-input>
					<el-button @click="submit(4)">套餐详情</el-button>
				</div>
				<div class="item">
					<div class="itemName">服务提成:</div>
					<el-input placeholder="请输入服务提成" v-model="groupGoodsRoyalty" style="width: 200px;margin-right: 12px;">
						<template slot="append">%</template>
					</el-input>
					<el-button @click="submit(2)">服务详情</el-button>
				</div>
				<div class="item">
					<div class="itemName">卡提成:</div>
					<el-input placeholder="请输入卡提成" v-model="virtualRoyalty" style="width: 200px;">
						<template slot="append">%</template>
					</el-input>
				</div>
				<div class="item">
					<div class="itemName">宠物提成:</div>
					<el-input placeholder="请输入服务提成" v-model="petRoyalty" style="width: 200px;margin-right: 12px;">
						<template slot="append">%</template>
					</el-input>
				</div>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit(0)">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
	
	export default {
		props:{
			commissionData:{   //跳转信息
				default(){
					return {}
				}
			}
		},
		data() {
			return {
				petRoyalty:0,
				goodsRoyalty:0,   //商品提成
				serviceRoyalty:0,  //服务提成
				groupGoodsRoyalty:0,  //套餐信息
				virtualRoyalty:0,  //卡提成
				dialogSetRoyalty_state:false,  //弹框状态
				routerType:'', //跳转状态
			}
		},
		mounted(){
			this.getCommissionData()
		},
		methods:{
			// 获取提成数据
			getCommissionData(){
				let params = {
					rouleId:this.commissionData.roule.id,
				}
				this.$http.get("/cms/RoyaltyConfig/GetRoyaltyConfig", params).then(res => {
					if (res.code === 0) {
						if(res.data == null){
							this.goodsRoyalty = 0   //商品提成
							this.serviceRoyalty = 0  //服务提成
							this.groupGoodsRoyalty = 0  //套餐信息
							this.virtualRoyalty = 0  //卡提成
							this.petRoyalty=0
						}else{
							this.goodsRoyalty = res.data.goodsRoyalty.royaltyRatio;
							this.serviceRoyalty = res.data.serviceRoyalty.royaltyRatio;
							this.groupGoodsRoyalty = res.data.groupGoodsRoyalty.royaltyRatio;
							this.virtualRoyalty = res.data.virtualRoyalty.royaltyRatio;
							this.petRoyalty=res.data.petRoyalty.royaltyRatio;
						}
					}
				})
			},
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:{
					routerType:0
				}})
			},
			// 提交
			submit(type){
				let params = {
					roleInfo:{
						projectId:this.commissionData.roule.id,
						projectName:this.commissionData.roule.name,
						projectCode:this.commissionData.roule.code
					},
					petRoyalty:Number(this.petRoyalty),
					goodsRoyalty:Number(this.goodsRoyalty),
					serviceRoyalty:Number(this.serviceRoyalty),
					groupGoodsRoyalty:Number(this.groupGoodsRoyalty),
					virtualRoyalty:Number(this.virtualRoyalty),
				}
				this.$http.post("/cms/RoyaltyConfig/SetRoyaltyConfig", params).then(res => {
					if (res.code === 0) {
						this.$emit("submit",{type:"success",data:{
							routerType:type,
							roule:this.commissionData.roule
						}})
					}
				})
			}
		}
	};
</script>
<style lang="scss" scoped>
	.item{
		display: flex;
		align-items: center;
		margin-top: 20px;
		.itemName{
			width: 90px;
			text-align: right;
			margin-right: 4px;
			font-size: 14px;
		}
	}
</style>
